import React from 'react';
import styles from './BannerGallery.module.scss';

import { IMAGES } from '../../images/images';

export interface BannerGalleryProps {
  setActiveImage: (image: string) => void;
}

export function BannerGallery({ setActiveImage }: BannerGalleryProps) {
  return (
    <div className={styles.container}>
      <div className={styles.highlight}>
        <ClickableGalleryImage
          src={IMAGES[0]}
          setActiveImage={setActiveImage}
        />
      </div>

      <div className={styles.others}>
        {IMAGES.slice(1).map((image) => (
          <ClickableGalleryImage
            key={image}
            src={image}
            setActiveImage={setActiveImage}
          />
        ))}
      </div>
    </div>
  );
}

interface ClickableGalleryImageProps {
  src: string;
  setActiveImage: (image: string) => void;
}

function ClickableGalleryImage({
  src,
  setActiveImage,
}: ClickableGalleryImageProps) {
  return (
    <div
      className={styles.image}
      style={{ backgroundImage: `url(${src})` }}
      onClick={() => setActiveImage(src)}
    />
  );
}
