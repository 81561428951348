import React from 'react';
import { TICK } from '../../images/icons';
import { scrollIdIntoView } from '../../utils/scrollIdIntoView';
import styles from './ContactForm.module.scss';

enum FormState {
  INPUTTING,
  SUBMITTING,
  SUBMITTED,
  FAILED,
}

const API_ENDPOINT =
  'https://mpi442ck33.execute-api.eu-west-2.amazonaws.com/staging/email';

async function sendMessage(data: {
  name: string;
  email: string;
  phone: string;
  message: string;
}) {
  fetch(API_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

function useInputValue(): [string, (event: any) => void] {
  const [value, setValue] = React.useState('');
  const handleEvent = React.useCallback(
    (event: any) => setValue(event.target.value),
    [setValue],
  );

  return [value, handleEvent];
}

export function ContactForm() {
  const [error, setError] = React.useState('');
  const [name, setName] = useInputValue();
  const [phone, setPhone] = useInputValue();
  const [email, setEmail] = useInputValue();
  const [message, setMessage] = useInputValue();

  const [formState, setFormState] = React.useState<FormState>(
    FormState.INPUTTING,
  );

  function handleError(newError: string) {
    setError(newError);
    setFormState(FormState.FAILED);
    scrollIdIntoView('error');
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();

    if (
      name.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      message.length === 0
    ) {
      handleError('Please fill out all details.');
      return;
    }

    setFormState(FormState.SUBMITTING);

    try {
      await sendMessage({ name, phone, email, message });
      setFormState(FormState.SUBMITTED);
    } catch (error) {
      console.error(error);
      handleError('Failed to send message. Please try again later.');
    }
  }

  const isDisabled =
    formState === FormState.SUBMITTING || formState === FormState.SUBMITTED;

  return (
    <div className={styles.container}>
      {formState === FormState.SUBMITTING && (
        <div className={styles.overlay}>
          <Spinner />
          <div className={styles.text}>Submitting...</div>
        </div>
      )}
      {formState === FormState.SUBMITTED && (
        <div className={styles.overlay}>
          <div className={styles.tick}>{TICK}</div>
          <div className={styles.text}>Submitted!</div>
          <p className={styles.subtext}>We'll be in contact soon.</p>
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className={isDisabled ? styles.isDisabled : undefined}
      >
        <div className={styles.field}>
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            disabled={isDisabled}
            value={name}
            onChange={setName}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="phone-number">Phone number</label>
          <input
            id="phone-number"
            type="tel"
            disabled={isDisabled}
            value={phone}
            onChange={setPhone}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="email">Email address</label>
          <input
            id="email"
            type="email"
            disabled={isDisabled}
            value={email}
            onChange={setEmail}
          />
        </div>

        <div className={styles.field}>
          <label htmlFor="message">Your message</label>
          <textarea
            id="message"
            disabled={isDisabled}
            value={message}
            onChange={setMessage}
          />
        </div>

        {formState === FormState.FAILED && (
          <div id="error" className={styles.error}>
            {error}
          </div>
        )}

        <div className={styles.submit}>
          <button disabled={isDisabled}>
            <span>Send message</span>
          </button>
        </div>
      </form>
    </div>
  );
}

function Spinner() {
  return (
    <div className={styles.spinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
