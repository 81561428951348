import React from "react";
import { EMAIL, FACEBOOK, PHONE } from "../../images/icons";
import styles from "./ContactOptions.module.scss";

export function ContactOptions() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Contact Us</div>

      <p className={styles.message}>
        Contact us directly using one or the options below, or send us a message
        and we'll get back to you.
      </p>

      <div className={styles.direct}>
        <ContactOption
          icon={PHONE}
          text="07862 882 088"
          href="tel:+447862882088"
        />
        <ContactOption
          icon={EMAIL}
          text="arsen@jdcontractors.uk"
          href="mailto:arsen@jdcontractors.uk"
        />
        <ContactOption
          icon={FACEBOOK}
          text="Message on Facebook"
          href="https://www.facebook.com/jdcontractorss"
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </div>
  );
}

interface ContactOptionProps {
  icon: JSX.Element;
  href: string;
  text: string;
  target?: string;
  rel?: string;
}

function ContactOption({ href, icon, text, target, rel }: ContactOptionProps) {
  return (
    <a className={styles.option} href={href} target={target} rel={rel}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{text}</div>
    </a>
  );
}
