import React from 'react';
import styles from './Services.module.scss';

import kitchenIcon from '../images/kitchen-icon.png';
import bathroomIcon from '../images/bathroom-icon.png';
import gasIcon from '../images/gas-icon.png';

export function Services() {
  return (
    <div id="services" className={styles.container}>
      <div className={styles.title}>Services</div>

      <div className={styles.services}>
        <div className={styles.service}>
          <img src={bathroomIcon} alt="none" />
          <div className={styles.text}>Bathroom installation</div>
        </div>
        <div className={styles.service}>
          <img src={kitchenIcon} alt="none" />
          <div className={styles.text}>Kitchen installation</div>
        </div>
        <div className={styles.service}>
          <img src={gasIcon} alt="none" />
          <div className={styles.text}>Certified gas works</div>
        </div>
      </div>
    </div>
  );
}
