import React, { useEffect } from 'react';
import { CHEVRON_LEFT, CHEVRON_RIGHT, CROSS } from '../images/icons';
import { IMAGES } from '../images/images';
import styles from './Gallery.module.scss';

interface GalleryProps {
  activeImage: string;
  setActiveImage: (activeImage: string | null) => void;
}

const MIN_INDEX = 0;
const MAX_INDEX = IMAGES.length - 1;

export function Gallery({ activeImage, setActiveImage }: GalleryProps) {
  const currentImageIndex = IMAGES.findIndex((image) => image === activeImage);

  const handleClose = () => {
    setActiveImage(null);
  };

  const handleBack = () => {
    const nextIndex =
      currentImageIndex === MIN_INDEX ? MAX_INDEX : currentImageIndex - 1;
    setActiveImage(IMAGES[nextIndex]);
  };
  const handleNext = () => {
    const nextIndex =
      currentImageIndex === MAX_INDEX ? MIN_INDEX : currentImageIndex + 1;
    setActiveImage(IMAGES[nextIndex]);
  };

  useHotkey('Escape', handleClose);
  useHotkey('ArrowLeft', handleBack);
  useHotkey('ArrowRight', handleNext);

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <Button text={CROSS} onClick={handleClose} />
        <Button text={CHEVRON_LEFT} onClick={handleBack} />
        <Button text={CHEVRON_RIGHT} onClick={handleNext} />
      </div>

      <div className={styles.image} onClick={handleNext}>
        <div style={{ backgroundImage: `url(${activeImage})` }} />
      </div>
    </div>
  );
}

interface ButtonProps {
  text: string | JSX.Element;
  onClick: () => void;
}

function Button({ text, onClick }: ButtonProps) {
  return (
    <button className={styles.button} onClick={onClick}>
      {text}
    </button>
  );
}

function useHotkey(key: string, onKeypress: () => void) {
  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === key) {
        onKeypress();
      }
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [key, onKeypress]);
}
