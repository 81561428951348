import React from "react";
import { EMAIL, PHONE } from "../images/icons";
import styles from "./SiteHeader.module.scss";
import { LOGO } from "../images/logo";
import { scrollIdIntoView } from "../utils/scrollIdIntoView";

export function SiteHeader() {
  return (
    <header className={styles.container}>
      <div className={styles.logo}>{LOGO}</div>

      <div className={styles.links}>
        <button onClick={() => scrollIdIntoView("services")}>Services</button>
        <button onClick={() => scrollIdIntoView("contact")}>Contact</button>
      </div>

      <div className={styles.divider}></div>

      <div className={styles.contact}>
        <a className={styles.option} href="tel:+447862882088">
          {PHONE}
          <div className={styles.text}>07862 882 088</div>
        </a>
        <a className={styles.option} href="mailto:arsen@jdcontractors.uk">
          {EMAIL}
          <div className={styles.text}>arsen@jdcontractors.uk</div>
        </a>
      </div>
    </header>
  );
}
