import React from 'react';
import { ContactForm } from './ContactForm';
import { ContactOptions } from './ContactOptions';
import styles from './ContactSection.module.scss';

export function ContactSection() {
  return (
    <div id="contact" className={styles.container}>
      <div className={styles.half}>
        <ContactOptions />
      </div>

      <div className={styles.half}>
        <ContactForm />
      </div>
    </div>
  );
}
