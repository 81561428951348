import React from 'react';
import styles from './Banner.module.scss';

import { BannerCTA } from './BannerCTA';
import { BannerGallery, BannerGalleryProps } from './BannerGallery';

interface BannerProps extends BannerGalleryProps {}

export function Banner({ setActiveImage }: BannerProps) {
  return (
    <div className={styles.container}>
      <BannerCTA />
      <BannerGallery setActiveImage={setActiveImage} />
    </div>
  );
}
