import React from 'react';

import { SiteFooter } from '../structure/SiteFooter';
import { SiteHeader } from '../structure/SiteHeader';
import { LandingBox } from '../structure/LandingBox';
import { Gallery } from '../structure/Gallery';

import { Banner } from '../sections/banner/Banner';
import { Services } from '../sections/Services';
import { ContactSection } from '../sections/contact/ContactSection';

export function Page() {
  const [activeImage, setActiveImage] = React.useState<string | null>(null);

  if (activeImage) {
    return (
      <Gallery activeImage={activeImage} setActiveImage={setActiveImage} />
    );
  }

  return (
    <>
      <SiteHeader />

      <LandingBox>
        <>
          <Banner setActiveImage={setActiveImage} />
          <Services />
          <ContactSection />
        </>
      </LandingBox>

      <SiteFooter />
    </>
  );
}
