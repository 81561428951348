import React from 'react';
import styles from './LandingBox.module.scss';

interface LandingBoxProps {
  children: JSX.Element;
}

export function LandingBox({ children }: LandingBoxProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
