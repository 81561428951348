import React from 'react';
import { scrollIdIntoView } from '../../utils/scrollIdIntoView';
import styles from './BannerCTA.module.scss';

export function BannerCTA() {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.description}>
          Providing a range of building services to both private and commercial
          clients.
        </div>
        <button
          className={styles.button}
          onClick={() => scrollIdIntoView('contact')}
        >
          Contact us for a quote today
        </button>
      </div>
    </div>
  );
}
