import React from "react";
import styles from "./SiteFooter.module.scss";

export function SiteFooter() {
  const date = new Date();
  return (
    <div className={styles.container}>
      <span>&copy; JD Contractors Ltd {date.getFullYear()}</span>
    </div>
  );
}
